import type { UmweltplakettenPosition } from '@gks/types'
import { klona } from 'klona/json'
import type { Kombination } from '@prisma/client'

export default defineStore('umweltplakette-checkout-store', () => {
  const order = ref(null)
  const positions = ref<UmweltplakettenPosition[]>([])

  const billingAddress = ref({
    fullName: '',
    streetAndHouseNumber: '',
    additionalInfo: '',
    companyName: '',
    postalCode: '',
    city: '',
  })
  
  const shippingAddress = ref({
    fullName: '',
    streetAndHouseNumber: '',
    additionalInfo: '',
    companyName: '',
    postalCode: '',
    city: '',
  })

  const email = ref('')

  const differentBillingAddress = ref(false)

  const { umweltplaketteVariantSKU } = useProductSkus()
  
  function startUmweltplakettenCheckout (kombination: Kombination) {
    const position: UmweltplakettenPosition = {
      sku: umweltplaketteVariantSKU,
      qty: 1,
      payload: {
        city: kombination.token,
        letters: kombination.letters,
        numbers: kombination.numbers
      }
    }

    clearPositions()
    addPosition(position)

    useRouter().push('/umweltplakette/checkout')
  }

  function addPosition (position: any) {
    positions.value.push(position)
  }

  function removePosition (index: number) {
    positions.value = klona(positions.value.filter((_, i) => i !== index))
  }

  function clearPositions () {
    positions.value = []
  }

  function setOrder (newOrder) {
    order.value = newOrder
  }

  const umweltplakettePosition = computed(() => {
    return positions.value
      .find(position => position.sku === umweltplaketteVariantSKU)
  })

  return {
    order,
    positions,
    billingAddress,
    shippingAddress,
    email,
    differentBillingAddress,
    umweltplakettePosition,

    startUmweltplakettenCheckout,
    addPosition,
    removePosition,
    clearPositions,
    setOrder
  }
}, {
  persist: {
    storage: persistedState.localStorage
  }
})
