// ! this is not a very performant way to do this, but it's the easiest way to do it for now
export function useProductIds () {
  const carWKZProductId = 'KZ-WA-4'
  const motorcycleWKZProductId = 'KZ-WM-4'
  const tractorWKZProductId = 'KZ-WT-4'
  const lightMotorcycleWKZProductId = 'KZ-WL-4'

  const wkzProductIds = [
    carWKZProductId,
    motorcycleWKZProductId,
    tractorWKZProductId,
    lightMotorcycleWKZProductId
  ]

  const bicycleWKZProductId = 'KZ-WF-4'
  const parkingPlateProductId = 'KZ-P'

  const carKZProductId = 'KZ-A'
  const motorcycleKZProductId = 'KZ-M'
  const tractorKZProductId = 'KZ-T'
  const lightMotorcycleKZProductId = 'KZ-LK'

  
  const kzProductIds = [
    carKZProductId,
    motorcycleKZProductId,
    tractorKZProductId,
    lightMotorcycleKZProductId
  ]
  
  const kennzeichenhalterProductId = 'KH-4'
  const kennzeichenHalterZweizeiligProductId = 'KH-Z-4'

  const umweltplaketteProductId = 'UP-1'

  return {
    carWKZProductId,
    motorcycleWKZProductId,
    tractorWKZProductId,
    lightMotorcycleWKZProductId,

    wkzProductIds,

    bicycleWKZProductId,

    parkingPlateProductId,

    carKZProductId,
    motorcycleKZProductId,
    tractorKZProductId,
    lightMotorcycleKZProductId,

    kzProductIds,

    kennzeichenhalterProductId,
    kennzeichenHalterZweizeiligProductId,

    umweltplaketteProductId
  }
}
