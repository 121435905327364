// ! this is not a very performant way to do this, but it's the easiest way to do it for now

export function useProductSkus () {
  const {
    bicycleWKZProductId,
    kennzeichenHalterZweizeiligProductId,
    kennzeichenhalterProductId,
    umweltplaketteProductId
  } = useProductIds()

  const standardCarVariantSKU = 'KZ-WA-4.KG-520x110.KO-STD.KF-S'
  const standardBicycleVariantSKU = `${bicycleWKZProductId}.KG-520x110.KO-STD.KF-S`
  const standardCarbonBicycleVariantSKU = `${bicycleWKZProductId}.KG-520x110.KO-STD.KF-C`
  const seasonBicycleVariantSKU = `${bicycleWKZProductId}.KG-520x110.KO-S.KF-S`
  const seasonCarbonBicycleVariantSKU = `${bicycleWKZProductId}.KG-520x110.KO-S.KF-C`
  const oldtimerBicycleVariantSKU = `${bicycleWKZProductId}.KG-520x110.KO-H.KF-S`
  const oldtimerCarbonBicycleVariantSKU = `${bicycleWKZProductId}.KG-520x110.KO-H.KF-C`
  const electricBicycleVariantSKU = `${bicycleWKZProductId}.KG-520x110.KO-E.KF-S`
  const electricCarbonBicycleVariantSKU = `${bicycleWKZProductId}.KG-520x110.KO-E.KF-C`
  const oldtimerSeasonBicycleVariantSKU = `${bicycleWKZProductId}.KG-520x110.KO-HS.KF-S`
  const oldtimerSeasonCarbonBicycleVariantSKU = `${bicycleWKZProductId}.KG-520x110.KO-HS.KF-C`
  const matBlackHolderVariantSKU = `${kennzeichenhalterProductId}.MATTSCHWARZ`
  const simpleFixHolderSKU = `${kennzeichenhalterProductId}.UNIVERSAL`
  const matBlackHolder180x200VariantSKU = `${kennzeichenHalterZweizeiligProductId}.KG-180x200.MATTSCHWARZ`
  const matBlackHolder220x200VariantSKU = `${kennzeichenHalterZweizeiligProductId}.KG-220x200.MATTSCHWARZ`
  const matBlackHolder240x130VariantSKU = `${kennzeichenHalterZweizeiligProductId}.KG-240x130.MATTSCHWARZ`
  const matBlackHolder255x130VariantSKU = `${kennzeichenHalterZweizeiligProductId}.KG-255x130.MATTSCHWARZ`

  const firstAidKitSKU = `VT.CAR`
  const bundleSKU = `KT.DEFAULT`
  
  const holderVariantSKUs = [
    matBlackHolderVariantSKU,
    simpleFixHolderSKU,
    matBlackHolder180x200VariantSKU,
    matBlackHolder220x200VariantSKU,
    matBlackHolder240x130VariantSKU,
    matBlackHolder255x130VariantSKU
  ]
  
  const bicycleVariantSKUs = [
    standardBicycleVariantSKU,
    standardCarbonBicycleVariantSKU,
    seasonBicycleVariantSKU,
    seasonCarbonBicycleVariantSKU,
    oldtimerBicycleVariantSKU,
    oldtimerCarbonBicycleVariantSKU,
    electricBicycleVariantSKU,
    electricCarbonBicycleVariantSKU,
    oldtimerSeasonBicycleVariantSKU,
    oldtimerSeasonCarbonBicycleVariantSKU
  ]
  
  const parkingPlateVariantSKU = 'KZ-P.KG-520x110'

  const umweltplaketteVariantSKU = `${umweltplaketteProductId}.DEFAULT`
  
  return {
    standardCarVariantSKU,
    standardBicycleVariantSKU,
    standardCarbonBicycleVariantSKU,
    seasonBicycleVariantSKU,
    seasonCarbonBicycleVariantSKU,
    oldtimerBicycleVariantSKU,
    oldtimerCarbonBicycleVariantSKU,
    electricBicycleVariantSKU,
    electricCarbonBicycleVariantSKU,
    oldtimerSeasonBicycleVariantSKU,
    oldtimerSeasonCarbonBicycleVariantSKU,
    matBlackHolderVariantSKU,
    simpleFixHolderSKU,
    matBlackHolder180x200VariantSKU,
    matBlackHolder220x200VariantSKU,
    matBlackHolder240x130VariantSKU,
    matBlackHolder255x130VariantSKU,
    firstAidKitSKU,
    bundleSKU,
    holderVariantSKUs,
    bicycleVariantSKUs,
    parkingPlateVariantSKU,
    umweltplaketteVariantSKU
  }
}
