import revive_payload_client_HrDROLe1hu from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5fWE8jYZOt from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_BAuPkciIO8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_hUFsoES4t5 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_oQvfEf8H0G from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_gtzIYZlXOb from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_rsHLS5bcOi from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_0NZS5lmhBm from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_typescript@5.6.2_vue@3.5.6/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import _64nuxt_scripts_D34jXE4xNh from "/vercel/path0/zulassung-digital/.nuxt/modules/@nuxt-scripts.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/zulassung-digital/.nuxt/components.plugin.mjs";
import prefetch_client_gGxxJx4d5h from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_LRNOQNQLtE from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.15.1_vite@5.4.5_vue@3.5.6/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_GRNT4PPvoV from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.15.1_vite@5.4.5_vue@3.5.6/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_QbE3kyrzix from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.15.1_vite@5.4.5_vue@3.5.6/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_c3IVjcSBgE from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_2WxfA1B0Dg from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import feathers_GSPs2K4t3q from "/vercel/path0/layers/shop-api-feathers/plugins/feathers.ts";
import cookie_banner_client_gpu0cuWAIt from "/vercel/path0/layers/base/plugins/cookie-banner.client.ts";
import trpc_UmSBE3Wgkx from "/vercel/path0/layers/base/plugins/trpc.ts";
import event_directive_iS46eQdIJo from "/vercel/path0/layers/google-analytics/plugins/event-directive.ts";
import script_6zAVbHB04w from "/vercel/path0/layers/google-analytics/plugins/script.ts";
import hotjar_client_HJG8no80GI from "/vercel/path0/zulassung-digital/plugins/hotjar.client.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/zulassung-digital/plugins/sentry.client.ts";
export default [
  revive_payload_client_HrDROLe1hu,
  unhead_5fWE8jYZOt,
  router_BAuPkciIO8,
  payload_client_hUFsoES4t5,
  navigation_repaint_client_oQvfEf8H0G,
  check_outdated_build_client_gtzIYZlXOb,
  chunk_reload_client_rsHLS5bcOi,
  plugin_vue3_0NZS5lmhBm,
  _64nuxt_scripts_D34jXE4xNh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_gGxxJx4d5h,
  slideovers_LRNOQNQLtE,
  modals_GRNT4PPvoV,
  colors_QbE3kyrzix,
  plugin_client_c3IVjcSBgE,
  plugin_2WxfA1B0Dg,
  feathers_GSPs2K4t3q,
  cookie_banner_client_gpu0cuWAIt,
  trpc_UmSBE3Wgkx,
  event_directive_iS46eQdIJo,
  script_6zAVbHB04w,
  hotjar_client_HJG8no80GI,
  sentry_client_shVUlIjFLk
]